import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody } from "reactstrap";
import { buttonAdd, updateButton, getButtonData, getbuttonslist } from "../../../actions/button";
import ls from "localstorage-slim";
import { useDispatch, useSelector } from 'react-redux';
import success from '../../../assets/images/icon/success.png';

ls.config.encrypt = true;

const AddButton = ({ buttonAdd, roles }) => {
  const dispatch = useDispatch();
  const buttonid = useSelector(state => state.button.buttonid);
  const btn = useSelector(state => state.button.btn);

  const [btnJson, setBtnJson] = useState({
    user_info: {
      firstname: "",
      lastname: "",
      email: ls.get("email"),
      mobile: "",
      mcc: "",
      dob: "",
      zipcode: "",
    },
    org_info: {
      apikey: ls.get("apikey"),
    },
    redirecturl: "",
    fullscreenmode: false,
    engagement_callback: true,
    include_pdf: false,
	
    lockurl: false,
    verification_toggle: true,
    multidownload: false,
    name: "New Button",
	emailnotetemplate:`DIRO support account verification\n\nHello <firstname>,\n\nUser has submitted their document for verification via no-code verification link. Please review the submission to approve/reject the document.\n\n https://client.diro.io/viewdoc/<sessionid>\n\nIn case of any help required, reach out to us at support@diro.io\n\nThanks\n\n<org logo>`
		,
    coverage: {
      category: "bank",
	
	//   note:"Invitation to share original screenshot for <org name>\n\nHello <first name>,\n\n<org name> has requested you to verify certain information from your bank  provider using DIRO. <click>\n\nThank you,\n\n<org name>\n\n<org logo>"
    },
    capture: {
      warn_case: [
        {
          keyword: "",
          type: "text",
          message: "full name",
          hinttext: "",
        },
      ],
    },
    mode: {
      type: "download",
    },
    mobileview: "Warning",
    alpha2code: null,
    auto_reject_baddoc: true,
    autoclickdata: {},
    country: null,
    customMobileWarningText: "",
    exitpage: {},
    notifySubmission: true,
    reject_reasons: ["Duplicate submission", "Not a bank statement / utility bill", "Does not contain full name"],
    reminders: [
      {
        trigger: "Document not submitted",
        additional_filter: "",
        delay: 1,
        activate: false,
        text: "",
        subject: ""
      }
    ],
    showpreview: true,
    smtp: [
      {
        username: "",
        password: "",
        server: "",
        port: "",
        security: false
      }
    ],
    welcomePage: {}
  });

  const [adminAccess, setAdminAccess] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (buttonid) {
      dispatch(getButtonData(buttonid));
    }
  }, [buttonid]);

  const addButton = () => {
    if (roles === "User" || roles === "Account") {
      setAdminAccess(false);
      return;
    }

    buttonAdd(btnJson);
    setShowModal(true);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    setAdminAccess(true);
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setBtnJson({ ...btnJson, name: newName });
  };

  const handleRenameConfirm = async() => {

    if(btnJson.name === "" || btnJson.name === null || btnJson.name.trim() === ""){
      setShowModal(true);
    }

    else{
    setShowModal(false);

      const payload = {
        apikey: ls.get("apikey"),
        buttonid: buttonid,
        data: {
          ...btnJson,
          name: btnJson.name
        }
      };
  
      await dispatch(updateButton(payload));
      dispatch(getbuttonslist());
    }
  };

  useEffect(() => {
    if (showModal) {
      setBtnJson(prevState => ({
        ...prevState,
        name: "New Button"
      }));
    }
  }, [showModal]);

  return (
    <Fragment>
      <button
        className="btn btn-warning float-right icon-hand raised-btn-info"
        style={{
          marginRight: "10px",
          marginLeft: "10px",
          padding: "1px 5px",
          width: "120px",
        }}
        onClick={addButton}
      >
        + Add button
      </button>
      {!adminAccess ? (
        <div>
          Access denied. Only admin has the rights to add new buttons.
        </div>
      ) : (
        ""
      )}
      <Modal isOpen={showModal} toggle={toggleModal}>
        <img src={success} alt="img" style={{ height: "8rem", width: "12rem", margin: "auto", display: "block", marginTop: "2rem" }} />
        <h4 style={{ fontSize: "20px", fontWeight: "500", textAlign: "center", marginBottom: "10px" }}>
          Button added successfully!
        </h4>
        <ModalBody style={{ height: "7rem", marginTop: "1rem" }}>
          <h4 style={{ textAlign: "center", fontWeight: "400", fontSize: "17px" }}>Enter the new name for the button</h4>
          <input
            style={{ width: "60%", margin: "0 auto" }}
            type="text"
            className="form-control"
            value={btnJson.name}
            required
            onChange={handleNameChange}
          />
          {btnJson.name === "" && (
            <p style={{ 
              color: "red",
              marginLeft: "6rem",
              fontSize: "small",
              fontWeight: 400}}>Field cannot be empty!</p>
          )}
        </ModalBody>
        <div style={{margin:"1rem 5rem 3rem 8rem"}}>
          <Button bordered onClick={toggleModal} style={{ marginRight: "2rem", backgroundColor: "white", fontSize: "1.1rem", padding: "0.5rem 1rem", color: "black" }}>
            Cancel
          </Button>{' '}
          <Button bordered color="primary" onClick={handleRenameConfirm} style={{ fontSize: "1.1rem", padding: "0.5rem 1rem" }}>
            Rename
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

AddButton.propTypes = {
  buttonAdd: PropTypes.func.isRequired,
  roles: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  button: state.button,
  roles: state.auth.roles,
});

export default connect(mapStateToProps, { buttonAdd })(AddButton);
