import { AUTH_MODE } from "../actions/types";
import ls from "localstorage-slim";

ls.config.encrypt = true;

const initialState = {
	authMode: ls.get("authMode") ? JSON.parse(ls.get("authMode")) : 1,
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case AUTH_MODE:
			ls.set("authMode", payload);
			return {
				...state,
				...payload,
				authMode: payload,
			};
		default:
			return state;
	}
}
